.Filter{
    font-family: Graphik Web;
	font-size:1em;
	text-align:left;
}

.Filter .error{width: 100%;border:2px solid #cc2929;background-color:rgba(204,41,41,0.15)}

.Filter button#boton {
	background-color: #A100FF;
	border: 0;
    width: 32px;
    height: 32px;
    padding: 0px;
}
.White {
	text-align:left;
	background-color: #FFFFFF;
}
.Super { 
    height: 18px;
}
.SeparatorV {
	height: 6px;
	text-align:center;
}
.Title { 
    font-family: Graphik Web;
	font-style: inherit;
	font-weight: bold;
	font-size: 4em;
	left: 80px;
}