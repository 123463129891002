.Form{font-family:Graphik Web;font-size:1em;text-align:left;}
.Form .error{width: 100%;border:2px solid #cc2929;background-color:rgba(204,41,41,0.15)}


.Form input#yes,.Form input#no{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 50%;
    width: 0.9em;
	height: 0.9em;
	border: thin solid #A100FF;
    margin: 0.25em;
    vertical-align: middle;
} 
.Form input#yes:checked,.Form input#no:checked{
	border: 0.45em solid #A100FF;
}
.Form #upload{padding-left:20vw}.Form #info{border-bottom:1px dotted black}
.Form #tooltiptext{font-size:0.7em;font-style:italic;text-align:justify;opacity:1;transition:opacity 0.25s;font-weight:normal}
.Form #tooltip:hover #tooltiptext{opacity:1}.Form p{margin:auto;font-size:1.25em;padding:7vh 1vh 0;background-color:rgba(184,184,184,0.089);border:2px dashed gray;height:20vh;font-style:italic;text-align:center}
.Form h2{font-family:Graphik Web;font-size:1em;font-weight:500;line-height:1.3em;text-align:left;margin:0;text-align: center;padding: 15px 0 5px 0;}
.Form h3{font-family:Graphik Web;font-size:0.70em;font-weight:500;font-style:italic;line-height:1.3em;text-align:left;margin:0;padding: 0 0 5px 0;}
.Form select {
	width: 100%;
	display: block;
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #444;
	line-height: 1.3;
	padding: 8px;
	max-width: 100%; 
	margin: 0;
	border: 2px solid #A100FF;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #FFFFFF;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.Form .dm {
	width: 100%;
	display: block;
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #444;
	line-height: 1.3;
	padding: 8px;
	max-width: 100%; 
	margin: 0;
	border: 2px solid #A100FF;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #d3c7c798;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.Form select:hover {
	border: 2px solid #A100FF;
}
.Form select:focus {
	border: 2px solid #A100FF;
}
.Form option {
	font-family: Graphik Web;
	font-size: 0.85rem;
	font-weight: 400;
}
*:focus {
    outline: thin solid #A100FF;
}
.Form .tittle {
	width: 100%;
	display: block;
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #444;
	line-height: 1.3;
	padding: .4em 1.4em .3em .8em;
	max-width: 100%; 
	margin: 0;
	border: 2px solid #A100FF;
	box-shadow: 0 1px 0 1px rgba(216, 167, 167, 0.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #FFFFFF;
	background-image: url('/');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
} 
.Form .description {
	width: 100%;
	display: block;
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #444;
	line-height: 1.3;
	padding: .4em 1.4em .3em .8em;
	max-width: 100%; 
	margin: 0;
	border: 2px solid #A100FF;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #FFFFFF;
	background-image: url('/');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.Form .owner {
	width: 100%;
	display: block;
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #444;
	line-height: 1.3;
	padding: .4em 1.4em .3em .8em;
	max-width: 100%; 
	margin: 0;
	border: 2px solid #A100FF;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #FFFFFF;
	background-image: url('/');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.Form .people {
	width: 100%;
	display: block;
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #444;
	line-height: 1.3;
	padding: .4em 1.4em .3em .8em;
	max-width: 100%; 
	margin: 0;
	border: 2px solid #A100FF;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #d3c7c798;
	background-image: url('/');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.Form button#boton {
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 1.3;
	padding: .4em 1.4em .3em .8em;
	max-width: 100%; 
	margin: 0;
	border: 2px solid #A100FF;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #A100FF;
	background-image: url('/');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}