.Title { 
    font-family: Graphik Web;
	font-style: inherit;
	font-weight: bold;
	font-size: 4em;
    left: 80px;
    text-align:center;
}
.Header {
    font-family: Graphik Web;
}
.Header .row {
    padding: 2%;
}
.Header .link-container {
    color: #444;
    width: 100%;
    font-weight: bold;
    font-size: 1.5rem;
    text-decoration: none;
}
.Header .link-container.left {
    text-align: right;
}
.Header .link-container.right {
    text-align: left;
}