.Mui-selected, .MuiTableRow-root:hover { 
    background: #ffffff !important 
}
.MuiTypography-body2 {
    font-family: Graphik Web;
}
.DownloadIcon { 
    font-family: Graphik Web;
    font-style: inherit;
    font-weight: bold;
    font-size: 0.5em;
}
.UpdateIconActive { 
    font-family: Graphik Web;
    font-style: inherit;
    font-weight: bold;
    font-size: 0.5em;
    color: red;
}
.UpdateIconDeactive { 
    font-family: Graphik Web;
    font-style: inherit;
    font-weight: bold;
    font-size: 0.5em;
}
.DeleteIconActive { 
    font-family: Graphik Web;
    font-style: inherit;
    font-weight: bold;
    font-size: 0.5em;
    color: red;
}
.DeleteIconDeactive { 
    font-family: Graphik Web;
    font-style: inherit;
    font-weight: bold;
    font-size: 0.5em;
}
