.Admin{font-family:Graphik Web;font-size:1em;text-align:left;font-weight: bold;}
.select {
	display: block;
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #444;
	line-height: 1.3;
	padding: 8px;
	max-width: 100%; 
	margin: 0;
	border: 2px solid #A100FF;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #FFFFFF;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

button#botonUpdateTag {
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 1.3;
	padding: .4em 1.4em .3em .8em;
	max-width: 100%; 
	margin-top: 10px;
	border: 2px solid #A100FF;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #A100FF;
	background-image: url('/');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

button#botonInsertNewTag {
	font-size: 0.9em;
	font-family: Graphik Web;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 1.3;
	padding: .4em 1.4em .3em .8em;
	max-width: 100%; 
	margin-top: 10px;
	border: 2px solid #A100FF;
	box-shadow: 0 2px 0 2px rgba(0,0,0,.03);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #A100FF;
	background-image: url('/');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.iconExportButton {
	border-radius: 0%;
	font-family: Graphik Web;
    font-style: inherit;
	font-size: 1.2em;
};