.NavBar {
    display: block;
    height: 50px;
    font-family: Graphik Web;
    padding: 0%;
    margin: 0%;
    width: 100%;
}
.NavBar .row {
    padding: 0%;
}
.NavBar .col {
    padding: 0%;
}
.NavBar .link-container {
    color: #ffffff;
    width: 100%;
    font-weight: bold;
    font-size: 1.5rem;
    text-decoration: none;
    padding: 0%;
}
.NavBar .link-container-small {
    color: #ffffff;
    width: 100%;
    font-weight: bold;
    font-size: 1.3rem;
    text-decoration: none;
    padding: 2%;
}
.NavBar .link-container.left {
    text-align: right;
    padding: 0%;
}
.NavBar .link-container.right {
    text-align: left;
    padding: 0%;
}
.NavBar .link-container.center {
    text-align: center;
    padding: 0%;
}

