.Footer {
    font-family: Graphik Web;
}
.Footer .link-container-small {
    color: #444;
    width: 100%;
    font-weight: bold;
    font-size: 0.85rem;
    text-decoration: none;
}
.Footer .link-container-tiny {
    color: #444;
    width: 100%;
    font-weight: bold;
    font-size: 0.65rem;
    text-decoration: none;
    margin-bottom: 0px;
}

