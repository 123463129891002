.App{
    top: 0px;
    margin:0;
    background-image: url('/images/1920_background.png');
    background-repeat: no-repeat;
    background-color: #F7F5F5;
}
.Toastify__toast-container {
    width: 400px;
    text-align: center;
}